import type { DirectiveBinding } from 'vue'
import type { HTMLElementCustom } from './types'

export const handleClickOutside = (el: HTMLElement, event: Event, binding: DirectiveBinding) => {
  if (!(el === event.target || el.contains(event.target as Node))) binding.value(event)
}

export default {
  beforeMount: (el: HTMLElementCustom, binding: DirectiveBinding) => {
    el.clickOutsideEvent = (event: MouseEvent) => handleClickOutside(el, event, binding)

    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted: (el: HTMLElementCustom) => {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
}
