<script lang="ts" setup>
import { defineProps, defineEmits, ref, watch, onMounted } from 'vue'
import type { Props } from './types'

const emit = defineEmits(['update:modelValue'])

const props = defineProps<Props>()

const { initialMin, initialMax, minRange, maxRange, gap, id } = props

const minValue = ref(initialMin || 0)
const maxValue = ref(initialMax || maxRange || 10000)
const minInterval = minRange || 0
const maxInterval = maxRange || 10000
const interval = props.interval || 1
const rangeGap = gap || 500
const rangeBar = ref(null)

watch(() => props.initialMin, (value) => {
  minValue.value = value || 0
  updateRangeBar('min')
})

watch(() => props.initialMax, (value) => {
  maxValue.value = (value >= maxRange) ? maxRange : (value || maxRange || 10000)
  updateRangeBar('max')
})

const updateInterval = (e) => {
  const range = e.target.classList.contains('min-range') ? 'min' : 'max'
  updateRangeBar(range)
}

const updateRangeBar = (range) => {
  const minVal = parseInt(minValue.value)
  const maxVal = parseInt(maxValue.value)

  const diff = maxVal - minVal

  if (diff < rangeGap) {
    if (range === 'min') {
      minValue.value = maxVal - rangeGap
    } else {
      maxValue.value = minVal + rangeGap
    }
  } else {
    minValue.value = minVal
    maxValue.value = maxVal
    rangeBar.value.style.left = `${((minVal - minInterval) / (maxInterval - minInterval)) * 100}%`
    rangeBar.value.style.right = `${100 - (((maxVal - minInterval) / (maxInterval - minInterval)) * 100)}%`
  }

  emit('update:modelValue', { minValue: minValue.value, maxValue: maxValue.value })
}

onMounted(() => {
  rangeBar.value.style.left = `${((minValue.value - minInterval) / (maxInterval - minInterval)) * 100}%`
  rangeBar.value.style.right = `${100 - ((maxValue.value - minInterval) / (maxInterval - minInterval)) * 100}%`
})
</script>

<script lang="ts">
export default {
  name: 'MinMaxRange'
}
</script>
<template>
  <div class="relative p-8">
    <div class="w-full bg-carbon-light-50 h-4 relative rounded-4">
      <div
        :id="id"
        ref="rangeBar"
        class="bg-brand-600 h-full absolute rounded-4" />
    </div>

    <div class="roomie-range__input">
      <input
        v-model="minValue"
        type="range"
        :data-lh-id="`${id}-min-input`"
        class="min-range"
        :min="minInterval"
        :max="maxInterval"
        :step="interval"
        @input="updateInterval">

      <input
        v-model="maxValue"
        type="range"
        :data-lh-id="`${id}-max-input`"
        class="max-range"
        :min="minInterval"
        :max="maxInterval"
        :step="interval"
        @input="updateInterval">
    </div>
  </div>
</template>
<style lang="scss">
/* Remove Arrows/Spinners */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.roomie-range__input {
  position: relative;

  input {
    position: absolute;
    width: 100%;
    height: 5px;
    background: none;
    top: -5px;
    pointer-events: none;
    cursor: pointer;
    appearance: none;

    &::-webkit-slider-thumb {
      @apply cursor-pointer bg-primary-600;

      pointer-events: auto;
      appearance: none;
      border-radius: 9999px;
      border: 2px solid #3ecfaf;
      z-index: 2;
      width: 24px;
      height: 24px;
      margin-top: -10px;
      box-shadow: 0 4px 8px rgba(62 207 175 / 50%);
    }

    &::-moz-range-thumb {
      @apply cursor-pointer bg-primary-600;

      pointer-events: auto;
      border-radius: 9999px;
      border: 2px solid #3ecfaf;
      z-index: 2;
      width: 24px;
      height: 24px;
      margin-top: -10px;
      box-shadow: 0 4px 8px rgba(62 207 175 / 50%);
    }

    &::-webkit-slider-runnable-track {
      border-radius: 3px;
      height: 4px;
    }

    &::-moz-range-track {
      border-radius: 3px;
      height: 4px;
    }
  }
}
</style>
